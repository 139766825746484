<template>
    <Loading :isLoading="isLoading" />
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <div>
                    <div class="mb-5 text-3xl sm:text-center mt-10 sm:mt-0 md:text-left lg:text-left">Documentos</div>
                    <label for="input_ine_if_on_both_sides_documents">Subir los documentos solicitados: INE/IFE por ambos lados:</label>
                    <div class="w-full py-2 px-5 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none"  v-on:click="getFile('input_ine_if_on_both_sides_documents')">
                        <div id="select-file">
                            <button type="button">
                                Seleccionar Archivo
                            </button>
                            <div style='height: 0px;width:0px; overflow:hidden;'>
                                <input id="input_ine_if_on_both_sides_documents" type="file" multiple
                                :accept="documentsAllowed.join(',')"
                                @change="loadDocument($event,false,'input_ine_if_on_both_sides_documents')" />
                            </div>
                        </div>
                    </div>
                
                    <div style="margint-top:10px;margint-bottom:10px" class="w-full py-2 px-5 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none">
                        <div v-for="(document, index) in imagesIneIFE " :key="index">
                            <div style="display: flex;width:100%;cursor: pointer;margin-top:10px;margin-bottom:10px" @click="deleteElement(imagesIneIFE,index)"> 
                                <div style="width:90%;">{{document.name}}</div> 
                                <div style="width:10%;text-align: right;"><strong>X</strong></div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="mt-5">
                    <label for="input_proof_of_address_documents">Comprobante de domicilio:</label>
                    <div class="w-full py-2 px-5 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none"  v-on:click="getFile('input_proof_of_address_documents')">
                        <div id="select-file">
                            <button type="button">
                                Seleccionar Archivo
                            </button>
                            <div style='height: 0px;width:0px; overflow:hidden;'>
                                <input id="input_proof_of_address_documents" type="file" multiple
                                :accept="documentsAllowed.join(',')"
                                @change="loadDocument($event,false,'input_proof_of_address_documents')" />
                            </div>
                        </div>
                    </div>
                
                    <div style="margint-top:10px;margint-bottom:10px" class="w-full py-2 px-5 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none">
                        <div v-for="(document, index2) in proofOfResidence" :key="index2">
                            <div style="display: flex;width:100%;cursor: pointer;margin-top:10px;margin-bottom:10px" @click="deleteElement(proofOfResidence,index2)"> 
                               
                                <div style="width:90%;">{{document.name}}</div> 
                                <div style="width:10%;text-align: right;"><strong>X</strong></div>
                             
                            </div>
                        </div>
                    </div>
                </div> 

                <div class="w-full flex justify-center">
                   <input type="button" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img :src="imageCategory" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, toRef, onMounted, onBeforeMount, watch } from 'vue';
import { getTokenDecoden } from "@/helpers/tokenauth";
import { helpers, requiredIf } from '@vuelidate/validators';
import ValidationsStructure from '@/vuelidate_rules/VidaSimple/VidaSimple.json';
import Document from "@/classes/Document.js";
import Issuance from "@/classes/PolicyIssuance.js";

import Swal from 'sweetalert2';
import Loading from '@/components/Loading/VueLoading.vue';
import { useRouter } from 'vue-router';
import moment from 'moment';

export default {
    props:{
        category: {
            type: String,
            default: () => "personal-data"
        },
        lastPage: {
            type: Number,
            default: 0
        }
    },
    setup(props){
        const isLoading = ref(true);
        const reRender = ref(null);
        
        const indexCategory =  ref(0);

        const validations = ref({});
        const goBack = toRef(props, 'lastPage')

        let vidaSimpleValidations = ref({});
        let vidaSimpleModel = ref({});
        let isActive = ref(false);
        let imageCategory = ref('');

        const router = useRouter();

        const documentsAllowed = ['jpeg', 'jpg', 'png', 'pdf'];
        const documentClass = ref(null);
        const issuanceClass = ref(null);

        let imagesIneIFE = ref([]);
        let positionImageIne = ref(-1);
        let proofOfResidence = ref([]);
        let statusIneIfe = ref(500);
        let statusProofOfResidence = ref(500);
        
        watch(goBack, (val)=> {
            if(val>0){
                back();
            }else if(indexCategory.value){
                console.log("Aun no hay pagina hacia atras", val);
            }
        });

        onBeforeMount(()=> {
           isActive.value = isValidForm();
        });

        onMounted(async()=>{
            if(props.category != 'personal-data'){
                documentClass.value = new Document();
                issuanceClass.value = new Issuance({});

                getImageCategory('documents');
                getDataItemStorage();

                let formValidation = getValidations();
                validations.value = formValidation;
                reRender.value = true;
            }
            isLoading.value = false;

        });

        const isValidForm = () => {

            let active = false;
            if(!storageExist('document_ine') || !storageExist('document_proof_of_address')){
                return active;
            }

            let documentsIne = getStorage('document_ine');
            let documentProofOfAddress = getStorage('document_proof_of_address');
            
            
             if ( documentsIne.length == 2 && documentProofOfAddress.length == 1 ) {
                 let countFile = 0;

                documentsIne.forEach( document => {

                    if ( document.hasOwnProperty("url") ) {

                        countFile++;
                        ( countFile == 2 ) ? active = true : active = false;;
                    } 
                    else {
                        active = false;
                    }
                 });

                 ( documentProofOfAddress[0].hasOwnProperty("url") && active ) ? active = true : active = false;

                return active;
             }
        }

        const getFile = (id_input_file) => {
            return document.getElementById(id_input_file).click()
        };

        const loadDocument = async (event, isDrag, id_of_input) => {
            let files = [];

            if (isDrag){
                files = event.dataTransfer.files;
            } 
            else {
                files = event.target.files;
                
                if (( files.length > 2 && id_of_input == 'input_ine_if_on_both_sides_documents' ) || ( imagesIneIFE.value.length == 2  && id_of_input == 'input_ine_if_on_both_sides_documents')) {
                    Swal.fire({
                        title: "Aviso",
                        html: "No se permite agregar más de dos archivos",
                        icon: "warning",
                    });
                  return;
                }

                if (( files.length > 1 && id_of_input == "input_proof_of_address_documents" ) || ( proofOfResidence.value.length == 1  && id_of_input == "input_proof_of_address_documents" )) {

                        Swal.fire({
                            title: "Aviso",
                            html: "No se permite agregar más de 1 archivo",
                            icon: "warning",
                        });
                  return;
                }
            }
            
            
            for (const file of files){
                const fileAttributes = documentClass.value.getDocumentAttributesSimple(file);
                const isValidFormat = documentClass.value.validateTypeFormat( documentsAllowed, fileAttributes.extension );
                const exceedTheMaximunSize = documentClass.value.exceedTheMaximunSize( 1487653, fileAttributes.size );

                if(exceedTheMaximunSize){
                    Swal.fire({
                        title: "Aviso",
                        html: `El peso del archivo sobrepasa 
                            el limite establecido de 1487653 MB`,
                        icon: "error",
                    });
                    return;
                }

                if(!isValidFormat){
                    Swal.fire({
                        title: "Aviso",
                        html: `El formato del archivo no es el solicitado.`,
                        icon: "error",
                    });
                    return;
                }
                
                const base64image = await documentClass.value.serialize(file);

                if(id_of_input == "input_ine_if_on_both_sides_documents"){
                    
                    if(imagesIneIFE.value.length > 0){
                        let isExist = 0;
                        
                        imagesIneIFE.value.forEach((element) => {
                            if(element.name == fileAttributes.name){
                                isExist++;
                            }                        
                        });

                        proofOfResidence.value.forEach((element) => {
                            if(element.name == fileAttributes.name){
                                isExist++
                            }                        
                        });

                        if(isExist > 0){

                            Swal.fire({
                                title: "Aviso",
                                html: `Este documento con el nombre <strong>${fileAttributes.name} </strong> ya existe.`,
                                icon: "error",
                            });

                            return;

                        } else {

                              positionImageIne.value == 0 ? imagesIneIFE.value.unshift({name:fileAttributes.name,extension:fileAttributes.extension,base64_file:base64image}) : imagesIneIFE.value.push({name:fileAttributes.name,extension:fileAttributes.extension,base64_file:base64image});
                        }

                    } else {
                        
                        positionImageIne.value == 0 ? imagesIneIFE.value.unshift({name:fileAttributes.name,extension:fileAttributes.extension,base64_file:base64image}) : imagesIneIFE.value.push({name:fileAttributes.name,extension:fileAttributes.extension,base64_file:base64image});
                    }
                }

                if(id_of_input == "input_proof_of_address_documents"){
                    if(proofOfResidence.value.length > 0){
                        let isExist=0;
                        imagesIneIFE.value.forEach((element) => {
                            if(element.name == fileAttributes.name){
                                isExist++
                            }                        
                        });
                        proofOfResidence.value.forEach((element) => {
                            if(element.name == fileAttributes.name){
                                isExist++
                            }                        
                        });
                        if(isExist>0){
                            Swal.fire({
                                title: "Aviso",
                                html: `Este documento con el nombre <strong>${fileAttributes.name} </strong> ya existe.`,
                                icon: "error",
                            });
                            return ;
                        }else{
                            proofOfResidence.value.push({name:fileAttributes.name,extension:fileAttributes.extension,base64_file:base64image});
                        }
                    }else{
                        proofOfResidence.value.push({name:fileAttributes.name,extension:fileAttributes.extension,base64_file:base64image});
                    }
                }

                saveImageLocalStorage();

            }
        }

        const saveImageLocalStorage = () => {
            if( imagesIneIFE.value.length == 2 && proofOfResidence.value.length == 1 ){
                localStorage.setItem("document_ine", JSON.stringify(imagesIneIFE.value))
                localStorage.setItem("document_proof_of_address", JSON.stringify(proofOfResidence.value))                
               
                isActive.value = true; 

            } else {
                isActive.value = false;
            }
        }

        const deleteElement = (element, position) => {

            positionImageIne.value = position;

            Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:"¿Seguro que desea eliminar este archivo?",
                    confirmButtonText: "Sí",
                    showCancelButton: true,
                    cancelButtonText: "No"
                }).then(async (result)=>{

                    if (result.value) {
                        if(element[position].url != undefined){

                            const token = await issuanceClass.value.getToken().then(resp => resp.data.response.token_auth);
                            const response = await issuanceClass.value.deleteFiles(token, [element[position].url]);
                            if(response.status == 200){

                                element.splice(position, 1);
                                saveImageLocalStorage();
                                Swal.fire({
                                    title: "Tarea terminada",
                                    html: `El archivo fue eliminado`,
                                    icon: "success",
                                });  

                            }

                            if(response.status == 422){
                                Swal.fire({
                                    title: "Aviso",
                                    html: `El Documento seleccionado no existe y ya fue borrado.`,
                                    icon: "warning",
                                });  
                            }

                        }else{
                            element.splice(position,1);
                            saveImageLocalStorage();
                        }

                       
                    }
                });
        }

        function getDataItemStorage(){
            if(localStorage.getItem('document_ine') || localStorage.getItem('document_proof_of_address')){
                imagesIneIFE.value = JSON.parse(localStorage.getItem("document_ine") || "");
                proofOfResidence.value = JSON.parse(localStorage.getItem("document_proof_of_address") || "");

                if(imagesIneIFE.value.length == 2 && proofOfResidence.value.length == 1){
                    isActive.value = true; 
                }else{
                    isActive.value = false;
                }
            }
        }

        const getImageCategory = (category) => {
            imageCategory.value = require(`@/assets/Cuenta/vidaSimple/${category}.svg`);
        }
        
        const next = async () => {

            if (isValidForm()) {
                isLoading.value = true;
                router.push({ path: "/cuenta/emision/vida-simple/detalle" });

                return;
            }
            if( imagesIneIFE.value.length > 0 && proofOfResidence.value.length > 0 ){
                const auth = getTokenDecoden();
                isLoading.value = true;


                let ImagesINE = [];
                let ProofOfResidence = [];
                
                const localstorage = JSON.parse(localStorage.getItem("quote-data") || "{}");
                const rfc = localstorage.contracting_data.contractor_other_than_the_applicant == "si"? localstorage.contracting_data.input_rfc : auth.obj.person["rfc"];

                imagesIneIFE.value.map((element, index)=>{
                    const keys = (Object.keys(element));
                    if(keys.includes("base64_file")){
                        element.name=`${rfc}-INE-${index+1}-${moment().format('YYYY-MM-DD h:mm:ss')}`
                        ImagesINE.push(element);
                    }
                });

                proofOfResidence.value.map((element, index)=>{
                    const keys = (Object.keys(element));
                    if(keys.includes("base64_file")){
                        element.name=`${rfc}-COMPROBANTE-DOMICILIO-${index+1}-${moment().format('YYYY-MM-DD h:mm:ss')}`
                        ProofOfResidence.push(element);
                    }
                });

                
                const token = await issuanceClass.value.getToken().then(resp => resp.data.response.token_auth);
                const ineFilesToUpload = await imagesIneIFE.value.filter( file => file.hasOwnProperty('base64_file'));
                
                if(ineFilesToUpload.length > 0){

                const response_imagesIneIFE  = await issuanceClass.value.uploadFiles(token, ineFilesToUpload);
                statusIneIfe.value = response_imagesIneIFE.status;

                if(response_imagesIneIFE.status == 200){
                    if(response_imagesIneIFE.data.response.documents_processed.length > 0){

                            let position = imagesIneIFE.value.findIndex(image => image.hasOwnProperty('base64_file'));                  
                            imagesIneIFE.value.splice(position, 1, response_imagesIneIFE.data.response.documents_processed[0]);

                            position = imagesIneIFE.value.findIndex(image => image.hasOwnProperty('base64_file'));
                             
                            if ( position > 0 ) {
                                imagesIneIFE.value.splice(1, 1, response_imagesIneIFE.data.response.documents_processed[1]);
                            }
                            
                            saveImageLocalStorage();
                        }
                    } 
                    else {
                         Swal.fire({
                            title: "Aviso",
                            html: `Hubo un error al subir el documento de INE/IFE`,
                            icon: "error",
                        });
                    }
                }

                if(proofOfResidence.value.length == 1 ) {
                    if (proofOfResidence.value[0].hasOwnProperty('base64_file')) {
                        const response_proofOfResidence = await issuanceClass.value.uploadFiles(token, proofOfResidence.value);
                        statusProofOfResidence.value = response_proofOfResidence.status;

                        if(response_proofOfResidence.status == 200){
                            if(response_proofOfResidence.data.response.documents_processed.length > 0){
                                proofOfResidence.value.splice(0, 1, response_proofOfResidence.data.response.documents_processed[0]); 
                                saveImageLocalStorage();
                            }  
                            else {
                            
                            Swal.fire({
                                title: "Aviso",
                                html: `Hubo un error al subir el documento <strong>${ProofOfResidence[0].name} </strong> de comprobante de domicilio`,
                                icon: "error",
                                });
                            }
                        }
                    }
                }

                  
                if( ( (statusProofOfResidence.value == 200 && statusIneIfe.value == 200 ) || ( statusIneIfe.value == 500 && statusProofOfResidence.value == 200 ) || ( statusIneIfe.value == 200 && statusProofOfResidence.value == 500 ) )  ){
                    router.push({ path: "/cuenta/emision/vida-simple/detalle" });
                }
            } 
            else {
                Swal.fire({
                    title: "Aviso",
                    html: `Es necesario subir los documentos de comprobante de domicilio y de INE/IFE`,
                    icon: "error",
                });  
            }
        }

        const back = () => {
           console.log("atras !!    ");
           router.push({path: '/cuenta/emision/vida-simple'});
        }
        
        const changeModelPersonalData = (values) => {
            /* isActive.value = !isEmptyObject(userInfo.value); */
        }

        const createStorage = (name, value) => {
            localStorage.setItem(name, JSON.stringify(value));
        }
        
        const getStorage = (name) => {
            return JSON.parse(localStorage.getItem(name));
        }

        const storageExist = (name) => {
            return (localStorage.getItem(name))? true: false;
        }

        function isEmptyObject(obj) {
            return Object.keys(obj).length === 0;
        }

        const getValidations =  () => {    
            let validations = {};
            let model = {};

            validations = {   
                ine_if_on_both_sides_documents : {
                    required: helpers.withMessage(ValidationsStructure.alcohol.consume_alcoholic.required.message, requiredIf(function(){
                        return true
                    })),
                },
                proof_of_address_documents: {
                    required: helpers.withMessage(ValidationsStructure.alcohol.alcohol_type_list.required.message, requiredIf(function(){
                        return false
                    })),
                }
            };

            model = {
                ine_if_on_both_sides_documents: "",
                proof_of_address_documents: ""
            };
        
            vidaSimpleValidations.value = validations;
            vidaSimpleModel.value = model;
        }

        return {
            changeModelPersonalData,
            next,
            isLoading,
            validations,
            vidaSimpleModel,
            isActive,
            imageCategory,
            documentsAllowed,
            loadDocument,
            getFile,
            imagesIneIFE,
            proofOfResidence,
            deleteElement
        }
    },
    components:{
        Loading
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}
</style>